

import React, { useState, useEffect } from 'react'
import { debounce } from '../utils/helpers'
import { ToggleButtonRounded } from './ModalStyledC';
import './Navbar.css'

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);

  }, [prevScrollPos, visible, handleScroll]);

  const navbarStyles = {
    position: 'fixed',
    height: '80px',
    width: '100%',
    textAlign: 'center',
    transition: 'top 0.2s'
  }

  return (
    <div className='header' style={{ ...navbarStyles, top: visible ? '0' : '-80px' }}>
            <div className='container'> 
            <h2 className='text-gray-700 font-bold'>Polydot.network</h2>
            <div>
            <span className="hidden lg:inline font-medium text-lg text-gray-500">BRIDGING</span><span className="hidden lg:inline pl-8 font-medium text-lg text-gray-500">OUR</span><span className="hidden lg:inline pl-8 font-medium text-lg text-gray-500">DIGITAL</span><span className="hidden lg:inline pl-8 font-medium text-lg text-gray-500">FUTURES</span>
                </div>
                <div className='btn-group pr-2 mb-2'>
                  <ToggleButtonRounded><span className="mf:hidden">Domain </span><span>For Sale</span></ToggleButtonRounded>  
                </div> 
            </div>
    </div>
  );
};

export default Navbar ;
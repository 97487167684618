import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ModalBox = styled(motion.div)`
  position: relative;
  height: 200px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled(motion.div)`
padding: 0px;
`;

export const Container = styled.div`
  height: 100vh;
  background: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CloseButton  = styled(motion.button)`
  right: 0px;
  border: none;
  background: none;
  width: 44px;
  &:hover { 
    cursor: pointer;
  }
`;

export const ToggleButtonRounded = styled(motion.button)`
padding: 4px 12px;
border: 1px solid var(--primary);
background-color: var(--primary);
margin-top: 9px;
color: #fff;
border-radius:8px 8px 8px 8px;
font-size: 1rem;
font-weight: 600;
cursor: pointer;
`;

export const ToggleButton = styled(motion.button)`
padding: 4px 12px;
border: 1px solid var(--primary);
background-color: var(--primary);
margin-top: 9px;
color: #fff;
border-radius:1px 1px 15px 15px;
font-size: 1rem;
font-weight: 600;
cursor: pointer;
`;

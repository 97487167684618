import React, { useState, useEffect } from 'react'
import { AnimatePresence } from "framer-motion";
import { ToggleButton } from "./ModalStyledC"
import { ToggleButtonRounded } from "./ModalStyledC"
import useModal from '../hooks/useModal'
import useModalForm from '../hooks/useModalForm'
// import { framerLogger } from "../stateLogger";
import Modal from "./Modal";
import axios from 'axios'
import './Featured.css'

const Featured = (props, { setCount, count, handleIncrement, handleModalFormAnim }) => {
  // Modal BG state 
  const { modalBGOpen, close, open } = useModal();
  // Modal Form state 
  const { modalFormOpen, closeForm, openForm } = useModalForm();

  const increment = () => {
    setCount(count + 1)
  };

  // API data
  const [data, setData] = useState(null);
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "80"},
  };

 // const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=15&page=1&sparkline=false'
 const url = 'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Ctether%2Cpolkadot%2Cbinancecoin%2Cmatic-network&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false&precision=2'

  useEffect(() => {
    axios.get(url).then((response) => {
      setData(response.data)
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  // console.log(data)
  if (!data) return null
  return (
    <>
      <div className='featured z-10'>
        <div className='container'>
          {/* Left */}
          <div className='left ml-6 mt-8'>
            <div className="navbarheight"></div>
            <h2 className="subheader font-bold mb-2 mf:hidden xs:hidden">Domain for sale</h2>
            <div className='mf:mt-[8px]'><span className="text-gray-600/50 font-semibold">Valuation: </span><span className="text-gray-700 font-semibold">$240,000.00</span>
              <div className="py-2 pb-2">
                <ToggleButtonRounded
                  whileTap={{ scale: 0.98 }}
                  onClick={() => (props.handleModalFormAnim() , props.handleIncrement() , open())}>
                  Buy Domain
                </ToggleButtonRounded>
              </div>
              <span className="text-gray-600/50 font-semibold">Offers also accepted in:</span></div>
          </div>
          <div className='right mt-8 mf:mt-[110px] md:mt-[110px] lg:mt-[110px] mf:scale-80 grid lg:grid-rows-3 grid-cols-2  gap-5'>
          <div className='card bg-white w-[160px] h-[160px]'>
          <div className='top mt-2'>
                {/* <img src={BTC} alt='/' /> */}
                <img src="img/btc-icon.png" alt="BTC icon" />
                {/*  <img src={data[0].image} alt='' /> */}
              </div>
              <div>
              <div className='text-xl font-semibold text-gray-600'>{'Bitcoin'}</div>
                <p className='graysmtext'>{(240000 / (data.bitcoin.usd)).toFixed(2)} BTC</p>
              </div>
              <ToggleButton
                whileTap={{ scale: 0.98 }}
                onClick={() => (props.handleModalFormAnim(), props.handleIncrement(), open())}>
                Buy Domain
              </ToggleButton>
            </div>  
            <div className='card bg-white w-[160px] h-[160px]'>
            <div className='top mt-2'>
                {/*     <img src={data[1].image} alt='' /> */}
                <img src="img/eth-icon.png" alt="ETH icon" />
              </div>
              <div>
              <div className='text-xl font-semibold text-gray-600'>{'Ethereum'}</div>
                <p className='graysmtext'>{(240000 / (data.ethereum.usd)).toFixed(2)} ETH</p>
              </div>
              <ToggleButton
                whileTap={{ scale: 0.98 }}
                className='font-semibold'
                onClick={() => (props.handleModalFormAnim(), props.handleIncrement(), open())}>
                Buy Domain
              </ToggleButton>
            </div>
            <div className='card bg-white w-[160px] h-[160px]'>
            <div className='top mt-2'>
                <img src="img/bnb-icon.png" alt="BNB icon" />
              </div>
              <div>
                <div className='text-xl font-semibold text-gray-600'>{'Binance'}</div>
                <p className='graysmtext'>{(240000 / (data.binancecoin.usd)).toFixed(2)} BNB</p>
              </div>
              <ToggleButton
                whileTap={{ scale: 0.98 }}
                onClick={() => (props.handleModalFormAnim(), props.handleIncrement(), open())}>
                Buy Domain
              </ToggleButton>
            </div>
            <div className='card bg-white w-[160px] h-[160px]'>
            <div className='top mt-2'>
                <img src="img/tether-icon.png"  alt='Tether' />
              </div>
              <div>
                <div className='text-xl font-semibold text-gray-600'>{'Tether USDT'}</div>
                <p className='graysmtext'>{(240000 / (data.tether.usd)).toFixed(0)} USD</p>
              </div>
              <ToggleButton
                whileTap={{ scale: 0.98 }}
                onClick={() => (props.handleModalFormAnim(), props.handleIncrement(), open())}>
                Buy Domain
              </ToggleButton>
            </div>
            <div className='card bg-white w-[160px] h-[160px]'>
              <div className='top mt-2'>
                <img src="img/poly-icon.png" alt="Polygon icon" />
              </div>
              <div>
                <div className='text-xl font-semibold text-gray-700'>{'Polygon'}</div>
                <p className='graysmtext'>{(240000 / (data['matic-network'].usd)).toFixed(0)} MATIC</p>
              </div>
              <ToggleButton className='mb-4'
                whileTap={{ scale: 0.98 }}
                onClick={() => (props.handleModalFormAnim(), props.handleIncrement(), open())}>
                Buy Domain
              </ToggleButton>
            </div>
            <div className='card bg-white w-[160px] h-[160px]'>
            <div className='top mt-2'>
              <img src="img/polk-icon.png" alt="Polkadot icon" />
              </div>
              <div>
                <div className='text-xl font-semibold text-gray-600'>{'Polkadot'}</div>
                <p className='graysmtext'>{(240000 / (data.polkadot.usd)).toFixed(0)} DOT</p>
              </div>
              <ToggleButton
                whileTap={{ scale: 0.98 }}
                onClick={() => (props.handleModalFormAnim() , props.handleIncrement() , open())}>
                Buy Domain
              </ToggleButton>
            </div>
          </div>
        </div>
      </div>
      <ModalContainer>
        {modalBGOpen && (
          <Modal modalBGOpen={open} showModal={openForm} handleClose={close} handleIncrement={handleIncrement} handleModalFormAnim={handleModalFormAnim} count={count} {...props} />
        )}
      </ModalContainer>
    </>
  )
}
const ModalContainer = ({ children, label }) => (
  // Enables the animation of components that have been removed from the tree
  <AnimatePresence
    // Disable any initial animations on children that
    // are present when the component is first rendered
    initial={false}
    // Only render one component at a time.
    // The exiting component will finish its exit
    // animation before entering component is rendered
    exitBeforeEnter={true}
  // Fires when all exiting nodes have completed animating out
  //    onExitComplete={() => framerLogger(label)}
  >
    {children}
  </AnimatePresence>
);
export default Featured;

import { useState } from "react";

// Centralizes pop-up modals backdrop control

const useModal = () => {

  const [modalBGOpen, setModalBGOpen] = useState(false);

  const close = () => setModalBGOpen(false);
  const open = () => setModalBGOpen(true);

  return { modalBGOpen, close, open};
};

export default useModal;

import React, { useState } from 'react'
import Navbar from './components/Navbar'
import { motion } from "framer-motion"
//  import useFooterAnim from './hooks/useFooterAnim'
import Featured from './components/Featured'
import Particles from 'react-tsparticles'
import Observer from 'tailwindcss-intersect'
import './App.css'

function App() {
  // To update Modal onClick state
  const [count, setCount] = useState(0);  // State - Count
  const handleIncrement = () => {  // Our state altering function
    setCount(count + 1)
  }

  // To manage state to Framer-Motion toggle to animate the navbar and footer in App.js
  const [menuOpen, setMenu] = useState(true);
  const handleModalFormAnim = () => {
    setMenu((menuOpen) => !menuOpen)
  }

  // our animation variants which is using framer-motion handled in the above useEffect
  const navbarVariants = {
    closed: { opacity: 0, y: (-80)},
    open: { opacity: 1, y: (0)},
  };
  
  const footerVariants = {
    closed: {opacity: 0, y: (45) },
    open: { opacity: 1, y: (0) },
  };

  // Particles...
  Observer.start();

  return (
    <div className="App ">
      <motion.div  transition={{type: 'tween', ease: 'anticipate', duration: 1.2}} animate={menuOpen ? "open" : "closed"} variants={navbarVariants}>
        <Navbar />
      </motion.div>
      <Featured count={count} handleIncrement={handleIncrement} handleModalFormAnim={handleModalFormAnim} menuOpen={menuOpen} />
      
      {/* // our hidden navbar+footer slidein/out on increment test button */}
      <motion.button className="bg-red-400 rounded-xl p-4 m-8 font-semibold invisible" onClick={() => setMenu((menuOpen) => !menuOpen) & (handleIncrement())}>Clicks<h3>{count}</h3></motion.button>
     
      {/* All Other-Devices footer */}
      <div className='p-0 fixed left-0 bottom-0 w-screen text-center transition-opacity duration-1000  md:opacity-100 bg-transparent opacity-0  duration-800 font-medium mf:invisible xs:invisible'>
        <motion.footer className="bg-white h-[45px] leading-[45px] text-sm text-slate-500/75" transition={{ type: 'tween', ease: 'anticipate', duration: 1.1}} animate={menuOpen ? "open" : "closed"} variants={footerVariants}>COPYRIGHT © 2022 - 19TH PLATEAUX</motion.footer>
      </div>
     
      {/* the duplicate Mobile-First footer fades-in */}
      <div className="block text-xs leading-none text-center text-slate-500/80 p-4 my-4 sm:invisible md:invisible lg:invisible xl:invisible 2xl:invisible cursor-help opacity-0 intersect:opacity-100 transition duration-1000 intersect">COPYRIGHT © 2022 - 19TH PLATEUAX</div>
    </div>
  )
}
export default App;


import Particles from 'react-tsparticles'
const ParticleBackdrop = () => {

    const particlesInit = (main) => {
        console.log(main);
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
      };
      const particlesLoaded = (container) => {
       console.log(container);
      };
    return (
    <Particles
        className='w-full lg:w-auto h-full lg:h-auto z-200'
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: '#A5F3FC',
            },
          },
          fpsLimit: 60,
          fullScreen: {
            enable: false,
            zIndex: -10
          },
          particles: {
            rotate: {
              value: {
                min: 0,
                max: 360
              },
              direction: "random",
              animation: {
                enable: true,
                speed: 1
              }
            },
            number: {
              value: 36,
              density: {
                enable: false,
                value_area: 800
              }
            },
            color: {
              value: "#ffffff"
            },
            shape: {
              type: "polygon",
              stroke: {
                width: 0.5,
                color: "#ffffff",
                opacity: 0.3
              },
              polygon: {
                nb_sides: 5
              }
            },
            opacity: {
              value: 0.8,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0,
                sync: false
              }
            },
            size: {
              value: 39.45738208161363,
              random: true,
              anim: {
                enable: true,
                speed: 3,
                size_min: 30,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 200,
              color: "#ffffff",
              opacity: 0.7,
              width: 2
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: false,
                mode: "grab"
              },
              onclick: {
                enable: false,
                mode: "push"
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1
                }
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 6,
                speed: 2
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          detectRetina: true,
        }}
      />
  )
}

export default ParticleBackdrop

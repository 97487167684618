import React from "react";
import { AnimatePresence } from "framer-motion";
import { ModalBox, ModalContent, CloseButton } from "./ModalStyledC";
import Backdrop from "./ModalBackdrop";
  
const Modal = ({ showModal, handleClose, handleModalFormAnim, handleIncrement}) => {
  return ( 
    <>
    <Backdrop>
    <AnimatePresence >  
        {showModal && (   
          <ModalBox className="mf:top-[-30px] xs:top-[150px] sm:top-[150px] md:top-[93px] lg:top-[150px] xl:top-[150px]"
            initial={{ opacity: 0, y: 0, scale: 0.1 }}
            animate={{ opacity: 1, y: 0, scale: 0.60, transition: { type: "spring", stiffness: 80}  }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.4 } }} >
            <ModalContent
              initial={{ y: 0, opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { delay: 0 , duration: 0.1 } }}>
      {/* modal content begin */}
      <div className="w-full max-h-screen"> 
    <div className="max-w-3xl mx-auto px-6 sm:px-6 lg:px-8 bg-gray-300 rounded-xl shadow-lg">
        <div className="bg-transparent w-full p-4 sm:p-12 -mt-64">
        <div className="delbtn"><CloseButton
        initial={{ x: 0 }}      
        animate={{ x: 0, transition: { duration: 0.3 }}}
        whileHover={{ scale: 0.95 }}
        whileTap={{ scale: 0.90 }}
        onClick={() => (handleClose(), handleIncrement(), handleModalFormAnim())}><div className="text-3xl bg-slate-400 mb-4 px-3 border-gray-500 font-semibold border-b-4 rounded text-gray-500">X</div></CloseButton></div>
            <p className="text-3xl text-center text-gray-400 mb-10">EMAIL BID DETAILS TO:</p>
            <p className="text-3xl text-center text-gray-400 mb-10">OBAC550 (At) gnail dot com</p>
            <form action="" method="post">
                <div className="md:flex items-center mt-4">
                    <div className="w-full md:w-1/2 flex flex-col">
                        <label className="font-semibold text-2xl leading-none text-gray-500">BIDDERS NAME</label>
                        <input type="text" className="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded-xl border-gray-200" />
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col md:ml-6 md:mt-0 mt-4">
                        <label className="font-semibold text-2xl leading-none text-gray-500">COMPANY/OCCUPATION</label>
                        <input type="email" className="leading-none text-gray-900 p-3 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 border rounded-xl border-gray-200"/>
                    </div>
                </div>
                <div className="md:flex items-center ">
                    <div className="w-full flex flex-col mt-4">
                        <label className="font-semibold text-2xl leading-none text-gray-500">SUBJECT: DOMAIN BID</label>
                        <input type="text" className="leading-none text-gray-400 focus:outline-none focus:border-blue-700 mt-4 bg-gray-100 pl-4 border rounded-xl border-gray-200" readOnly="Bid for Polydot.Network"/>
                    </div>
                </div>
                <div>
                    <div className="w-full flex flex-col mt-4">
                        <label className="font-semibold text-2xl leading-none text-gray-500">YOUR BID DETAILS</label>
                        <textarea type="text" className="h-40 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-blue-700 mt-4 bg-gray-100 border rounded-xl border-gray-200"></textarea>
                    </div>
                </div>
                <div className="flex items-center justify-center w-full">
                    <button className="bg-blue-600 mt-4 hover:bg-blue-500 text-white text-2xl font-semibold py-2 px-8 border-b-4 border-blue-800 hover:border-blue-600 rounded-lg"> Send Bid </button>
                </div>
            </form>
        </div>
    </div>
 </div> 
                {/* modal content end */}
            </ModalContent>
          </ModalBox>
        )}
     </AnimatePresence>  
    </Backdrop>
    </>
  );
};


  

export default Modal;

import { useState } from "react";

// Centralizes modal form control
const useModalForm = () => {
  const [modalFormOpen, setModalFormOpen] = useState(false);

  const closeForm = () => setModalFormOpen(false);
  const openForm = () => setModalFormOpen(true);

  return { modalFormOpen, closeForm, openForm };
};

export default useModalForm;
